import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Alert,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import GoogleIcon from "../assets/google-icon.png";
import { useUserAuth } from "../context/userAuthContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn, googleSignIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate("/home");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/home");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: "100vh",
        padding: "0 20px",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Box
          p={4}
          boxShadow={6}
          borderRadius={3}
          style={{ backgroundColor: "#ffffff" }}
        >
          {error && (
            <Alert severity="error" style={{ marginBottom: "16px" }}>
              {error}
            </Alert>
          )}
          <Typography
            variant="h4"
            align="center"
            style={{ fontWeight: 600, marginBottom: "24px", color: "#3f51b5" }}
          >
            Bienvenido de Nuevo
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="email">Correo Electrónico</InputLabel>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ padding: "10px" }}
              />
            </FormControl>

            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="password">Contraseña</InputLabel>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ padding: "10px" }}
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              sx={{ mt: 3, py: 2, backgroundColor: "#3f51b5", color: "white" }}
              style={{
                fontSize: "1.1rem",
                textTransform: "none",
                fontWeight: 500,
                borderRadius: "8px",
              }}
            >
              Iniciar Sesión
            </Button>
          </form>

          <Box mt={3} textAlign="center">
            <Typography variant="body1" style={{ color: "#757575" }}>
              O
            </Typography>
          </Box>

          <Box mt={2}>
            <Button
              variant="outlined"
              onClick={handleGoogleSignIn}
              startIcon={<img src={GoogleIcon} alt="Google" width="20" />}
              fullWidth
              sx={{ py: 2 }}
              style={{
                color: "#3f51b5",
                borderColor: "#3f51b5",
                textTransform: "none",
                fontSize: "1.1rem",
                fontWeight: 500,
                borderRadius: "8px",
              }}
            >
              Iniciar Sesión con Google
            </Button>
          </Box>

          <Box mt={2}>
            <Link to="/phonesignup" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                fullWidth
                sx={{ py: 2, backgroundColor: "#000000", color: "white" }}
                style={{
                  fontSize: "1.1rem",
                  textTransform: "none",
                  fontWeight: 500,
                  borderRadius: "8px",
                }}
              >
                Iniciar con Celular
              </Button>
            </Link>
          </Box>

          <Typography
            variant="body2"
            align="center"
            mt={4}
            style={{ color: "#757575" }}
          >
            ¿No tienes una cuenta?{" "}
            <Link
              to="/registro"
              style={{ color: "#3f51b5", textDecoration: "none" }}
            >
              Crea una
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
