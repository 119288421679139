import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import {
  Button,
  Container,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  MenuItem,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { GoogleMap, LoadScript, Polygon, Circle } from "@react-google-maps/api";
import DeleteIcon from "@mui/icons-material/Delete";

const FormularioCotizacion = () => {
  const [cotizacionId, setCotizacionId] = useState(null);
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [tipoProyecto, setTipoProyecto] = useState("");
  const [equipos, setEquipos] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [monto, setMonto] = useState("");
  const [diasEntrega, setDiasEntrega] = useState("");
  const [horasEntrega, setHorasEntrega] = useState("");
  const [puntosArea, setPuntosArea] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [userLocation, setUserLocation] = useState({
    lat: 19.4326,
    lng: -99.1332,
  }); // Default location
  const [searchLat, setSearchLat] = useState("");
  const [searchLng, setSearchLng] = useState("");

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    marginTop: "16px",
    borderRadius: "8px",
    overflow: "hidden",
  };

  const mapOptions = {
    mapTypeId: "satellite",
    disableDefaultUI: false,
    streetViewControl: true, // Mostrar nombres de calles
    mapTypeControl: true, // Control de tipos de mapa
  };

  useEffect(() => {
    // Obtener ubicación del usuario
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) =>
        console.error("Error obteniendo ubicación del usuario: ", error)
    );

    // Crear una cotización en Firebase
    const createCotizacion = async () => {
      if (!cotizacionId) {
        const newCotizacionRef = doc(collection(db, "cotizaciones"));
        await setDoc(newCotizacionRef, { created: new Date() });
        setCotizacionId(newCotizacionRef.id);
      }
    };
    createCotizacion();
  }, [cotizacionId]);

  const handleMapClick = (event) => {
    const newPoint = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setPuntosArea((prevPoints) => [...prevPoints, newPoint]);
  };

  const handlePointDrag = (index, event) => {
    const updatedPoints = [...puntosArea];
    updatedPoints[index] = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setPuntosArea(updatedPoints);
  };

  const handleDeletePoint = (index) => {
    setPuntosArea(puntosArea.filter((_, i) => i !== index));
  };

  const handleSearchLocation = () => {
    if (searchLat && searchLng) {
      setUserLocation({
        lat: parseFloat(searchLat),
        lng: parseFloat(searchLng),
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const cotizacionRef = doc(db, "cotizaciones", cotizacionId);
      await updateDoc(cotizacionRef, {
        cliente: { nombre, correo },
        tipoProyecto,
        equipos,
        descripcion,
        monto: parseFloat(monto),
        tiempoEntrega: { dias: diasEntrega, horas: horasEntrega },
        areaTrabajo: puntosArea,
        completed: true,
      });
      setOpenDialog(true);
    } catch (error) {
      console.error("Error al enviar la cotización: ", error);
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{ padding: 3, backgroundColor: "#f9f9f9", borderRadius: 2 }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        align="center"
        color="primary"
      >
        Crear Cotización de Proyecto
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" color="textSecondary">
          Información del Cliente
        </Typography>
        <TextField
          label="Nombre del Cliente"
          fullWidth
          margin="normal"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
        />
        <TextField
          label="Correo Electrónico"
          fullWidth
          margin="normal"
          type="email"
          value={correo}
          onChange={(e) => setCorreo(e.target.value)}
        />
      </Box>

      <Divider sx={{ marginY: 2 }} />

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" color="textSecondary">
          Detalles del Proyecto
        </Typography>
        <TextField
          label="Tipo de Proyecto"
          fullWidth
          margin="normal"
          select
          value={tipoProyecto}
          onChange={(e) => setTipoProyecto(e.target.value)}
        >
          <MenuItem value="Equipamiento de Patrulla">
            Equipamiento de Patrulla
          </MenuItem>
          <MenuItem value="Busqueda y Rescate con Drone">
            Búsqueda y Rescate con Drone
          </MenuItem>
          <MenuItem value="Desarrollo de Software">
            Desarrollo de Software
          </MenuItem>
          <MenuItem value="Levantamiento Topografico">
            Levantamiento Topográfico
          </MenuItem>
          <MenuItem value="Levantamiento ortomosaico">
            Levantamiento ortomosaico
          </MenuItem>
        </TextField>
        <TextField
          label="Equipos a Utilizar"
          fullWidth
          margin="normal"
          multiline
          rows={2}
          value={equipos}
          onChange={(e) => setEquipos(e.target.value)}
        />
        <TextField
          label="Descripción del Proyecto o Producto"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
        />
        <TextField
          label="Monto (MXN)"
          fullWidth
          margin="normal"
          type="number"
          value={monto}
          onChange={(e) => setMonto(e.target.value)}
        />
        <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
          <TextField
            label="Tiempo de Entrega (Días)"
            fullWidth
            margin="normal"
            type="number"
            value={diasEntrega}
            onChange={(e) => setDiasEntrega(e.target.value)}
          />
          <TextField
            label="Tiempo de Entrega (Horas)"
            fullWidth
            margin="normal"
            type="number"
            value={horasEntrega}
            onChange={(e) => setHorasEntrega(e.target.value)}
          />
        </Box>
      </Box>

      <Divider sx={{ marginY: 2 }} />

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" color="textSecondary">
          Buscar Ubicación
        </Typography>
        <Box sx={{ display: "flex", gap: 2, marginTop: 1 }}>
          <TextField
            label="Latitud"
            fullWidth
            margin="normal"
            value={searchLat}
            onChange={(e) => setSearchLat(e.target.value)}
          />
          <TextField
            label="Longitud"
            fullWidth
            margin="normal"
            value={searchLng}
            onChange={(e) => setSearchLng(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchLocation}
          >
            Buscar
          </Button>
        </Box>
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" color="textSecondary">
          Área de Trabajo en el Mapa
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ marginBottom: 1 }}
        >
          Haz clic en el mapa para añadir puntos. Puedes arrastrarlos para
          ajustar el área de trabajo o eliminarlos.
        </Typography>

        {/* Mapa para seleccionar área de trabajo */}
        <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={userLocation}
            zoom={12}
            onClick={handleMapClick}
            options={mapOptions}
          >
            {puntosArea.map((punto, index) => (
              <Circle
                key={index}
                center={punto}
                radius={2}
                options={{
                  fillColor: "white",
                  fillOpacity: 1,
                  strokeColor: "white",
                  strokeWeight: 0,
                  draggable: true,
                }}
                onDragEnd={(event) => handlePointDrag(index, event)}
              />
            ))}
            {puntosArea.length >= 4 && (
              <Polygon
                paths={puntosArea}
                options={{
                  fillColor: "red",
                  fillOpacity: 0.2,
                  strokeColor: "red",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                }}
              />
            )}
          </GoogleMap>
        </LoadScript>

        {/* Lista de puntos con opción de eliminar */}
        <List
          dense
          sx={{
            marginTop: 2,
            backgroundColor: "#f1f1f1",
            borderRadius: 1,
            padding: 1,
          }}
        >
          {puntosArea.map((punto, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  color="secondary"
                  onClick={() => handleDeletePoint(index)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`Punto ${index + 1}`}
                secondary={`Lat: ${punto.lat.toFixed(
                  6
                )}, Lng: ${punto.lng.toFixed(6)}`}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      <Divider sx={{ marginY: 2 }} />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        fullWidth
        sx={{ paddingY: 1.5, fontWeight: "bold" }}
      >
        Enviar Cotización
      </Button>

      {/* Diálogo de confirmación */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <Typography variant="h6" align="center" gutterBottom>
            ¡Cotización Enviada Exitosamente!
          </Typography>
          <Button
            onClick={() => setOpenDialog(false)}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: 2 }}
          >
            Cerrar
          </Button>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default FormularioCotizacion;
