import React, { useMemo } from "react";
import { Box, Typography, Grid } from "@mui/material";
import HandymanIcon from "@mui/icons-material/Handyman";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";

// Este componente calcula y muestra las estadísticas para las inspecciones filtradas
const InspectionStats = ({ inspections }) => {
  const stats = useMemo(() => {
    let totalRepairs = inspections.length; // Total de documentos mostrados como reparaciones
    let totalPartsReplaced = 0;

    inspections.forEach((inspection) => {
      if (inspection.FusiblesReemplazados) {
        totalPartsReplaced += inspection.FusiblesReemplazados.length; // Contar partes reemplazadas
      }
    });

    return { totalRepairs, totalPartsReplaced };
  }, [inspections]);

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "#0d0d0d",
        borderRadius: "8px",
        boxShadow: "0 4px 16px rgba(0, 255, 255, 0.3)",
        mb: 4,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#00ffff",
          textAlign: "center",
          fontWeight: "bold",
          mb: 2,
        }}
      >
        Estadísticas de Inspecciones
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ElectricCarIcon sx={{ fontSize: 40, color: "#00ffff", mb: 1 }} />
            <Typography variant="h5" sx={{ color: "#ffffff" }}>
              {stats.totalRepairs}
            </Typography>
            <Typography variant="body1" sx={{ color: "#ffffff" }}>
              Reparaciones
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <HandymanIcon sx={{ fontSize: 40, color: "#00ffff", mb: 1 }} />
            <Typography variant="h5" sx={{ color: "#ffffff" }}>
              {stats.totalPartsReplaced}
            </Typography>
            <Typography variant="body1" sx={{ color: "#ffffff" }}>
              Partes Reemplazadas
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InspectionStats;
