import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
} from "@mui/material";
import * as XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ExportToExcel = ({ inspections }) => {
  const [open, setOpen] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    Folio: false,
    Fecha: true,
    Unidad: true,
    Comentario: true,
    ReparacionesRealizadas: true,
    Status: false,
    Usuario: false,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFieldChange = (field) => {
    setSelectedFields((prevFields) => ({
      ...prevFields,
      [field]: !prevFields[field],
    }));
  };

  const handleDownload = () => {
    const formattedData = inspections.map((inspection) => {
      const rowData = {};

      if (selectedFields.Folio) rowData.Folio = inspection.id;
      if (selectedFields.Fecha)
        rowData.Fecha = inspection.Date.toDate().toLocaleString();
      if (selectedFields.Unidad) rowData.Unidad = inspection.Unidad;
      if (selectedFields.Comentario)
        rowData.Comentario = inspection.Comentario || "N/A";
      if (selectedFields.ReparacionesRealizadas)
        rowData["Reparaciones Realizadas"] = inspection.FusiblesReemplazados
          ? inspection.FusiblesReemplazados.join(", ")
          : "N/A";
      if (selectedFields.Status) rowData.Status = inspection.Status;
      if (selectedFields.Usuario) rowData.Usuario = inspection.Usuario;

      return rowData;
    });

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Inspecciones");

    XLSX.writeFile(wb, "Relación_Reparaciones_RadioSistemas.xlsx");

    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<FileDownloadIcon />}
        sx={{
          bgcolor: "#00C49F",
          color: "#000",
          fontWeight: "bold",
          "&:hover": { backgroundColor: "#009688" },
          mb: 4,
        }}
      >
        Descargar Excel
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Selecciona los campos para descargar</DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFields.Folio}
                  onChange={() => handleFieldChange("Folio")}
                />
              }
              label="Folio"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFields.Fecha}
                  onChange={() => handleFieldChange("Fecha")}
                />
              }
              label="Fecha"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFields.Unidad}
                  onChange={() => handleFieldChange("Unidad")}
                />
              }
              label="Unidad"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFields.Comentario}
                  onChange={() => handleFieldChange("Comentario")}
                />
              }
              label="Comentario"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFields.ReparacionesRealizadas}
                  onChange={() => handleFieldChange("ReparacionesRealizadas")}
                />
              }
              label="Reparaciones Realizadas"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFields.Status}
                  onChange={() => handleFieldChange("Status")}
                />
              }
              label="Estado"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFields.Usuario}
                  onChange={() => handleFieldChange("Usuario")}
                />
              }
              label="Usuario"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleDownload} color="primary">
            Descargar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportToExcel;
