import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import {
  Container,
  Typography,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { GoogleMap, LoadScript, Polygon, Circle } from "@react-google-maps/api";

// Función para calcular el área de un polígono a partir de coordenadas en latitud y longitud
const calculatePolygonArea = (coordinates) => {
  let area = 0;
  const numPoints = coordinates.length;

  for (let i = 0; i < numPoints; i++) {
    const { lat: lat1, lng: lon1 } = coordinates[i];
    const { lat: lat2, lng: lon2 } = coordinates[(i + 1) % numPoints];

    area += lon1 * lat2 - lon2 * lat1;
  }

  return Math.abs(area * 0.5 * (111320 * 110540)); // Conversión aproximada a metros cuadrados
};

const VisualizarCotizacion = () => {
  const { uid } = useParams();
  const [cotizacion, setCotizacion] = useState(null);
  const [userLocation, setUserLocation] = useState({
    lat: 19.4326,
    lng: -99.1332,
  });
  const [areaMetrosCuadrados, setAreaMetrosCuadrados] = useState(null);

  useEffect(() => {
    const fetchCotizacion = async () => {
      try {
        const cotizacionRef = doc(db, "cotizaciones", uid);
        const docSnap = await getDoc(cotizacionRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setCotizacion(data);
          if (data.areaTrabajo && data.areaTrabajo.length > 0) {
            setUserLocation(data.areaTrabajo[0]);
            const area = calculatePolygonArea(data.areaTrabajo);
            setAreaMetrosCuadrados(area);
          }
        } else {
          console.error("No existe la cotización con ese UID.");
        }
      } catch (error) {
        console.error("Error al obtener la cotización:", error);
      }
    };
    fetchCotizacion();
  }, [uid]);

  const mapContainerStyle = {
    width: "100%",
    height: "500px", // Aumenta el tamaño del mapa para una mejor visibilidad
    marginTop: "16px",
    borderRadius: "8px",
    overflow: "hidden",
  };

  const mapOptions = {
    mapTypeId: "satellite",
    disableDefaultUI: false,
    streetViewControl: true,
    mapTypeControl: true,
    fullscreenControl: true,
    zoomControl: true,
  };

  const uidShort = uid.slice(-8).toUpperCase(); // Obtener los últimos 8 dígitos del UID

  if (!cotizacion) {
    return (
      <Container maxWidth="md" sx={{ padding: 3 }}>
        <Typography variant="h5" color="textSecondary" align="center">
          Cargando cotización...
        </Typography>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="md"
      sx={{ padding: 3, backgroundColor: "#f9f9f9", borderRadius: 2 }}
    >
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Cotización #{uidShort}
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" color="textSecondary">
          Información del Cliente
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText
              primary="Nombre del Cliente"
              secondary={cotizacion.cliente?.nombre || "Desconocido"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Correo Electrónico"
              secondary={cotizacion.cliente?.correo || "No proporcionado"}
            />
          </ListItem>
        </List>
      </Box>

      <Divider sx={{ marginY: 2 }} />

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" color="textSecondary">
          Detalles del Proyecto
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText
              primary="Tipo de Proyecto"
              secondary={cotizacion.tipoProyecto}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Equipos a Utilizar"
              secondary={cotizacion.equipos}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Descripción del Proyecto"
              secondary={cotizacion.descripcion}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Monto (MXN)"
              secondary={`$${cotizacion.monto}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Tiempo de Entrega"
              secondary={`${cotizacion.tiempoEntrega?.dias} días, ${
                cotizacion.tiempoEntrega?.horas || "0"
              } horas`}
            />
          </ListItem>
        </List>
      </Box>

      <Divider sx={{ marginY: 2 }} />

      {areaMetrosCuadrados && (
        <Typography
          variant="h6"
          color="primary"
          align="center"
          sx={{ marginBottom: 2 }}
        >
          Área de trabajo: {areaMetrosCuadrados.toFixed(2)} m²
        </Typography>
      )}

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" color="textSecondary">
          Área de Trabajo en el Mapa
        </Typography>
        <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={userLocation}
            zoom={15}
            options={mapOptions}
          >
            {cotizacion.areaTrabajo && cotizacion.areaTrabajo.length > 0 && (
              <>
                {cotizacion.areaTrabajo.map((punto, index) => (
                  <Circle
                    key={index}
                    center={punto}
                    radius={3}
                    options={{
                      fillColor: "white",
                      fillOpacity: 1,
                      strokeColor: "black",
                      strokeWeight: 1,
                    }}
                  />
                ))}
                <Polygon
                  paths={cotizacion.areaTrabajo}
                  options={{
                    fillColor: "rgba(255, 0, 0, 0.3)", // Ajuste de opacidad para mejor visualización
                    strokeColor: "red",
                    strokeOpacity: 0.9,
                    strokeWeight: 2,
                  }}
                />
              </>
            )}
          </GoogleMap>
        </LoadScript>
      </Box>
    </Container>
  );
};

export default VisualizarCotizacion;
