import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";

const CotizacionList = () => {
  const [cotizaciones, setCotizaciones] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCotizaciones = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "cotizaciones"));
        const cotizacionList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCotizaciones(cotizacionList);
      } catch (error) {
        console.error("Error al obtener las cotizaciones:", error);
      }
    };

    fetchCotizaciones();
  }, []);

  const handleCotizacionClick = (id) => {
    navigate(`/cotizacion/proyecto/${id}`);
  };

  return (
    <Container maxWidth="md" sx={{ padding: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Lista de Cotizaciones
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      <List>
        {cotizaciones.map((cotizacion) => (
          <Box key={cotizacion.id} sx={{ marginBottom: 2 }}>
            <ListItem
              button
              onClick={() => handleCotizacionClick(cotizacion.id)}
              sx={{
                backgroundColor: "#f1f1f1",
                borderRadius: 1,
                "&:hover": { backgroundColor: "#e0e0e0" },
              }}
            >
              <ListItemText
                primary={cotizacion.tipoProyecto || "Cotización sin título"}
                secondary={`Cliente: ${
                  cotizacion.cliente?.nombre || "Desconocido"
                }`}
              />
            </ListItem>
            <Divider />
          </Box>
        ))}
      </List>
    </Container>
  );
};

export default CotizacionList;
