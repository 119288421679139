import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import {
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";

const Documentacion = ({ folio }) => {
  const [data, setData] = useState({
    curp: "",
    afore: "",
    rfc: "",
    nss: "",
    cartillaMilitar: "",
    pasaporte: "",
    licencia: false,
    claseLicencia: "",
  });

  useEffect(() => {
    const loadData = async () => {
      const docRef = doc(db, "solicitudes_empleo", folio);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setData(docSnap.data().documentacion || {});
      }
    };
    if (folio) loadData();
  }, [folio]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    setData((prev) => ({ ...prev, licencia: e.target.checked }));
  };

  const handleSave = async () => {
    const docRef = doc(db, "solicitudes_empleo", folio);
    await updateDoc(docRef, { documentacion: data });
  };

  return (
    <Grid container spacing={2}>
      {/* Campos de documentación aquí, similar a lo mostrado en la implementación anterior */}
      <Grid item xs={6}>
        <TextField
          label="CURP"
          name="curp"
          variant="outlined"
          fullWidth
          value={data.curp}
          onChange={handleChange}
        />
      </Grid>
      {/* Agregar el resto de campos aquí */}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          fullWidth
        >
          Guardar Documentación
        </Button>
      </Grid>
    </Grid>
  );
};

export default Documentacion;
