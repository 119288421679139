import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Grid,
  Modal,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from "../../../firebase"; // Importamos Timestamp
import { useUserAuth } from "../../../context/userAuthContext";
import NuevaUnidad from "../../AdminDashboard/Unidad/NuevaUnidad";

const Step1Unidad = ({
  unidades,
  selectedUnidad,
  setSelectedUnidad,
  handleNext,
  setUnidades,
  setInspectionId, // Prop to set the inspection ID
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUnidades, setFilteredUnidades] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { user } = useUserAuth(); // Get the logged-in user's info

  useEffect(() => {
    setFilteredUnidades(
      unidades.filter((unidad) =>
        unidad.Unidad.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, unidades]);

  const handleSelectUnidad = (unidad) => {
    setSelectedUnidad(unidad.Unidad);
    setFilteredUnidades([unidad]); // Filtra solo la unidad seleccionada
  };

  const handleUnidadAdded = (newUnidad) => {
    setUnidades((prevUnidades) => [...prevUnidades, newUnidad]);
    setSelectedUnidad(newUnidad.Unidad);
    setSearchTerm("");
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleNextStep = async () => {
    if (!selectedUnidad) return;

    // Crear un nuevo documento de inspección en Firestore
    try {
      const fechaActual = Timestamp.now(); // Cambiado de new Date().toLocaleString()
      const docRef = await addDoc(collection(db, "inspecciones"), {
        Unidad: selectedUnidad,
        Usuario: user.email,
        Active: true,
        Timing: { Start: fechaActual, End: "" }, // Usamos Timestamp para ambas fechas
        Date: fechaActual, // Guardamos como Timestamp
        Status: "En Progreso", // Estado inicial de la inspección
      });

      // Establecer el ID de la inspección creada
      setInspectionId(docRef.id);

      // Avanzar al siguiente paso
      handleNext();
    } catch (error) {
      console.error("Error al crear la inspección:", error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ mb: 2, textAlign: "center" }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleOpenModal}
          sx={{
            color: "#00ffff",
            borderColor: "#00ffff",
            "&:hover": {
              backgroundColor: "#001f3f",
              borderColor: "#00C49F",
            },
            width: "100%",
            padding: 1.5,
            fontSize: "1rem",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Agregar Nueva Unidad
        </Button>
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#0d0d0d",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxWidth: "90vw",
            width: "400px",
          }}
        >
          <NuevaUnidad
            handleClose={handleCloseModal}
            onUnidadAdded={handleUnidadAdded}
          />
        </Box>
      </Modal>

      <Typography variant="h6" gutterBottom sx={{ color: "#00ffff" }}>
        Selecciona una Unidad
      </Typography>

      <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
        <TextField
          label="Buscar Unidad"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon sx={{ color: "#00ffff" }} />
              </IconButton>
            ),
            style: { color: "#00ffff" }, // Color del texto en el buscador
          }}
          sx={{
            label: { color: "#00ffff" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00ffff",
              },
              "&:hover fieldset": {
                borderColor: "#00ffff",
              },
            },
          }}
        />
      </Box>

      <Grid container spacing={2}>
        {filteredUnidades.map((unidad) => (
          <Grid
            item
            xs={6}
            key={unidad.id}
            onClick={() => handleSelectUnidad(unidad)}
          >
            <Box
              sx={{
                border: `2px solid ${
                  selectedUnidad === unidad.Unidad ? "#00C49F" : "#00ffff"
                }`,
                borderRadius: 2,
                padding: 2,
                textAlign: "center",
                backgroundColor:
                  selectedUnidad === unidad.Unidad ? "#001f3f" : "inherit",
                cursor: "pointer",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "#001f3f",
                },
              }}
            >
              {selectedUnidad === unidad.Unidad && (
                <CheckCircleIcon
                  sx={{ color: "#00C49F", fontSize: 24, mb: 1 }}
                />
              )}
              <Typography
                variant="h6"
                sx={{
                  color: "#00ffff",
                  fontWeight: "bold",
                }}
              >
                {unidad.Unidad}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#00ffff",
                  fontSize: "0.9rem",
                }}
              >
                Zona: {unidad.Zona}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 2, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextStep} // Llama a la función que crea el documento y avanza al siguiente paso
          disabled={!selectedUnidad}
          sx={{
            backgroundColor: "#00C49F",
            color: "#000000",
            "&:hover": {
              backgroundColor: "#009688",
            },
            width: "100%",
            padding: 1.5,
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Siguiente
        </Button>
      </Box>
    </Box>
  );
};

export default Step1Unidad;
