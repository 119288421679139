import React, { useState, useEffect, useMemo } from "react";
import { db } from "../../firebase";
import { collection, getDocs, query } from "firebase/firestore";
import {
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import InspectionStats from "./InspectionStats"; // Importamos el componente de estadísticas
import ExportToExcel from "./ExportToExcel"; // Importamos el componente para exportar a Excel

// Función para formatear la fecha de visualización desde Timestamp
const formatTimestamp = (timestamp) => {
  const date = timestamp.toDate();
  const day = date.getDate();
  const month = date.getMonth() + 1; // Los meses comienzan desde 0
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Retornar fecha en formato 'd/m/yyyy, hh:mm'
  return `${day}/${month}/${year}, ${hours}:${minutes}`;
};

// Normalizar las fechas para comparar solo la parte del día, mes y año
const normalizeDate = (date) => {
  const normalizedDate = new Date(date);
  normalizedDate.setHours(0, 0, 0, 0);
  return normalizedDate;
};

const InspectionList = () => {
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null); // Nuevo estado para manejar la fila seleccionada

  useEffect(() => {
    const fetchInspections = async () => {
      try {
        const q = query(collection(db, "inspecciones"));
        const querySnapshot = await getDocs(q);
        const inspectionsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Ordenamos las inspecciones por fecha de más reciente a más antigua
        inspectionsData.sort((a, b) => b.Date.toDate() - a.Date.toDate());

        setInspections(inspectionsData);
      } catch (err) {
        setError("Error al recuperar las inspecciones.");
      } finally {
        setLoading(false);
      }
    };

    fetchInspections();
  }, []);

  const filteredInspections = useMemo(() => {
    return inspections.filter((inspection) => {
      const isInUnitSearch = inspection.Unidad.toLowerCase().includes(
        searchTerm.toLowerCase()
      );

      const inspectionDate = inspection.Date.toDate();

      // Normalizamos la fecha seleccionada para ignorar las horas
      const normalizedStartDate = startDate ? normalizeDate(startDate) : null;
      const normalizedEndDate = endDate ? normalizeDate(endDate) : null;

      // Comparamos las fechas normalizadas (solo día, mes, año)
      const isInDateRange =
        (!normalizedStartDate ||
          normalizeDate(inspectionDate) >= normalizedStartDate) &&
        (!normalizedEndDate ||
          normalizeDate(inspectionDate) <= normalizedEndDate);

      return isInUnitSearch && isInDateRange;
    });
  }, [inspections, searchTerm, startDate, endDate]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchTerm("");
  };

  const handleRowClick = (id) => {
    setSelectedRow(id); // Establece la fila seleccionada
    window.open(`/inspeccion/${id}`, "_blank"); // Abre la URL en una nueva pestaña o ventana
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#0d0d0d"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center" variant="h6">
        {error}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        p: 3,
        bgcolor: "#0d0d0d",
        minHeight: "100vh",
        color: "#ffffff",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: "#00ffff",
          fontWeight: "bold",
          textAlign: "center",
          mb: 4,
        }}
      >
        Lista de Inspecciones
      </Typography>

      {/* Añadimos las estadísticas aquí */}
      <InspectionStats inspections={filteredInspections} />

      {/* Añadimos el botón de exportar a Excel aquí */}
      <ExportToExcel inspections={filteredInspections} />

      <Box
        sx={{
          mb: 3,
          maxWidth: "100%",
          margin: "0 auto",
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Buscar por Unidad"
          value={searchTerm}
          onChange={handleSearch}
          sx={{
            bgcolor: "#222",
            color: "#ffffff",
            borderRadius: "8px",
            flex: 1,
          }}
          InputProps={{
            style: { color: "#ffffff" },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#00ffff" }} />
              </InputAdornment>
            ),
          }}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <DatePicker
            label="Fecha inicio"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                sx={{ bgcolor: "#222", borderRadius: "8px", flex: 1 }}
              />
            )}
          />
          <DatePicker
            label="Fecha fin"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                sx={{ bgcolor: "#222", borderRadius: "8px", flex: 1 }}
              />
            )}
          />
        </LocalizationProvider>

        <Button
          variant="contained"
          onClick={handleClearFilters}
          sx={{ bgcolor: "#00ffff", color: "#0d0d0d", fontWeight: "bold" }}
        >
          Limpiar filtros
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ backgroundColor: "#0d0d0d" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "#00ffff" }}>Folio</TableCell>
              <TableCell sx={{ color: "#00ffff" }}>Nombre</TableCell>
              <TableCell sx={{ color: "#00ffff" }}>Fecha</TableCell>
              <TableCell sx={{ color: "#00ffff" }}>Técnico</TableCell>
              <TableCell sx={{ color: "#00ffff" }}>Estado</TableCell>
              <TableCell sx={{ color: "#00ffff" }}>Unidad</TableCell>
              <TableCell sx={{ color: "#00ffff" }}>Comentario</TableCell>
              <TableCell sx={{ color: "#00ffff" }}>
                Equipos Reemplazados
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInspections.map((inspection) => (
              <TableRow
                key={inspection.id}
                hover
                onClick={() => handleRowClick(inspection.id)} // Maneja el clic para seleccionar la fila
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedRow === inspection.id ? "#2c2c2c" : "inherit", // Cambia el color si es seleccionada
                }}
              >
                <TableCell sx={{ color: "#ffffff" }}>{inspection.id}</TableCell>
                <TableCell sx={{ color: "#ffffff" }}>
                  {inspection.Nombre}
                </TableCell>
                <TableCell sx={{ color: "#ffffff" }}>
                  {formatTimestamp(inspection.Date)}
                </TableCell>
                <TableCell sx={{ color: "#ffffff" }}>
                  {inspection.Usuario}
                </TableCell>
                <TableCell
                  sx={{
                    color:
                      inspection.Status === "Correcto" ? "#00ff00" : "#ff0000",
                  }}
                >
                  {inspection.Status}
                </TableCell>
                <TableCell sx={{ color: "#ffffff" }}>
                  {inspection.Unidad}
                </TableCell>
                <TableCell sx={{ color: "#ffffff" }}>
                  {inspection.Comentario ? inspection.Comentario : "N/A"}
                </TableCell>
                <TableCell sx={{ color: "#ffffff" }}>
                  {inspection.FusiblesReemplazados
                    ? inspection.FusiblesReemplazados.join(", ")
                    : "N/A"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InspectionList;
