import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import DatosPersonales from "./Form/DatosPersonales";
import Documentacion from "./Form/Documentacion";
import {
  Button,
  Container,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";

const Formulario = () => {
  const [folio, setFolio] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  // Pasos del formulario
  const steps = [
    <DatosPersonales folio={folio} />,
    <Documentacion folio={folio} />,
  ];

  useEffect(() => {
    const createFolio = async () => {
      if (!folio) {
        const newFolioRef = doc(collection(db, "solicitudes_empleo"));
        await setDoc(newFolioRef, { created: new Date() });
        setFolio(newFolioRef.id);
      }
    };
    createFolio();
  }, [folio]);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      const docRef = doc(db, "solicitudes_empleo", folio);
      await updateDoc(docRef, { completed: true });
      setOpenDialog(true);
    } catch (error) {
      console.error("Error al enviar el formulario: ", error);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h5" component="h2" gutterBottom>
        Solicitud de Empleo - Paso {currentStep + 1}
      </Typography>
      {steps[currentStep]}
      <div style={{ marginTop: "16px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBack}
          disabled={currentStep === 0}
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          style={{ marginLeft: "8px" }}
        >
          {currentStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
        </Button>
      </div>

      {/* Diálogo de confirmación */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <Typography variant="h6" align="center">
            ¡Solicitud Enviada Exitosamente!
          </Typography>
          <Button
            onClick={() => setOpenDialog(false)}
            variant="contained"
            color="primary"
            fullWidth
          >
            Cerrar
          </Button>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Formulario;
