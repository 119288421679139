import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import {
  TextField,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const DatosPersonales = ({ folio }) => {
  const [data, setData] = useState({
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombres: "",
    edad: "",
    domicilio: "",
    colonia: "",
    codigoPostal: "",
    telefono: "",
    sexo: "",
    ciudadEstado: "",
    lugarNacimiento: "",
    fechaNacimiento: "",
    nacionalidad: "",
    viveCon: [],
    dependientes: [],
    estatura: "",
    peso: "",
    estadoCivil: "",
  });

  useEffect(() => {
    const loadData = async () => {
      const docRef = doc(db, "solicitudes_empleo", folio);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setData(docSnap.data().datosPersonales || {});
      }
    };
    if (folio) loadData();
  }, [folio]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const docRef = doc(db, "solicitudes_empleo", folio);
    await updateDoc(docRef, { datosPersonales: data });
  };

  return (
    <Grid container spacing={2}>
      {/* Campos de datos personales aquí, similar a lo mostrado en la implementación anterior */}
      <Grid item xs={6}>
        <TextField
          label="Apellido Paterno"
          name="apellidoPaterno"
          variant="outlined"
          fullWidth
          value={data.apellidoPaterno}
          onChange={handleChange}
        />
      </Grid>
      {/* Agregar el resto de campos aquí */}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          fullWidth
        >
          Guardar Datos Personales
        </Button>
      </Grid>
    </Grid>
  );
};

export default DatosPersonales;
