import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import { useUserAuth } from "../../context/userAuthContext";
import MuiAlert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import Step1Unidad from "./Steps/Step1Unidad";
import Step2Fotos from "./Steps/Step2Fotos";
import Step3EstadoComentarios from "./Steps/Step3EstadoComentarios";
import Step4OficialFirma from "./Steps/Step4OficialFirma";

const NuevaInspeccion = () => {
  const { uid } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [unidades, setUnidades] = useState([]);
  const [oficiales, setOficiales] = useState([]);
  const [selectedUnidad, setSelectedUnidad] = useState("");
  const [selectedOficial, setSelectedOficial] = useState("");
  const [comentario, setComentario] = useState("");
  const [status, setStatus] = useState("Correcto");
  const [fotos, setFotos] = useState({});
  const [firma, setFirma] = useState(null);
  const [generalState, setGeneralState] = useState({
    Bocina: "Incorrecto",
    Radio: "Desprogramado",
    Torreta: "Incorrecto",
    DVR: "Incorrecto",
  });
  const [fusibles, setFusibles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [timing, setTiming] = useState({ Start: "", End: "" });
  const [inspectionId, setInspectionId] = useState(uid || "");

  const { user } = useUserAuth();

  useEffect(() => {
    const fetchUnidades = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "unidades"));
        const unidadesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUnidades(
          unidadesData.sort((a, b) => a.Unidad.localeCompare(b.Unidad))
        );
      } catch (error) {
        console.error("Error al obtener las unidades:", error);
      }
    };

    const fetchOficiales = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "oficiales"));
        const oficialesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          nombre: doc.data().nombre,
        }));
        setOficiales(
          oficialesData.sort((a, b) => a.nombre.localeCompare(b.nombre))
        );
      } catch (error) {
        console.error("Error al obtener los oficiales:", error);
      }
    };

    const fetchInspectionData = async (uid) => {
      try {
        const inspectionDoc = await getDoc(doc(db, "inspecciones", uid));
        if (inspectionDoc.exists()) {
          const data = inspectionDoc.data();
          setSelectedUnidad(data.Unidad);
          setSelectedOficial(data.Nombre);
          setComentario(data.Comentario);
          setStatus(data.Status);
          setGeneralState(data.EstadoGeneral || {});
          setFusibles(data.FusiblesReemplazados || []);
          setFotos(data.fotos || {});
          setFirma(data.Firma || null);
          setTiming(data.Timing || { Start: "", End: "" });
        } else {
          console.error(
            "No se encontró la inspección con el UID proporcionado."
          );
        }
      } catch (error) {
        console.error("Error al obtener la inspección:", error);
      }
    };

    fetchUnidades();
    fetchOficiales();

    if (uid) {
      fetchInspectionData(uid);
    }
  }, [uid]);

  const handleImageChange = (label, file) => {
    if (
      file &&
      file instanceof Blob &&
      file.size > 0 &&
      file.type.startsWith("image/")
    ) {
      try {
        const previewURL = URL.createObjectURL(file);
        setFotos((prev) => ({
          ...prev,
          [label]: { file, previewURL },
        }));
      } catch (error) {
        console.error("Error al generar la URL de previsualización:", error);
      }
    } else {
      console.error("El archivo seleccionado no es válido.");
      setFotos((prev) => ({
        ...prev,
        [label]: null,
      }));
    }
  };

  const handleFirmaPad = (signaturePad) => {
    const dataUrl = signaturePad.toDataURL("image/png");
    setFirma(dataUrl);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    if (!selectedUnidad || !selectedOficial) {
      setMessage("Por favor, selecciona una unidad y un oficial.");
      setOpenSnackbar(true);
      return;
    }

    setUploading(true);

    try {
      let newInspectionRef;
      if (inspectionId) {
        newInspectionRef = doc(db, "inspecciones", inspectionId);
      } else {
        newInspectionRef = await addDoc(collection(db, "inspecciones"), {
          Comentario: comentario,
          Date: Timestamp.now(), // Usamos Timestamp para la fecha actual
          "Inspection ID": "",
          Nombre: selectedOficial, // Asegúrate de guardar el nombre del oficial aquí
          Pago: "Por cobrar",
          Status: status,
          Unidad: selectedUnidad,
          Usuario: user.email,
          EstadoGeneral: generalState,
          FusiblesReemplazados: fusibles,
          fotos: {},
          Timing: { Start: Timestamp.now(), End: "" },
          Active: true,
        });
        setInspectionId(newInspectionRef.id);
      }

      const validFotos = Object.entries(fotos).filter(
        ([key, value]) => value && value.file
      );

      const uploadPromises = validFotos.map(([key, { file }]) => {
        const storageRef = ref(
          storage,
          `inspecciones/${inspectionId || newInspectionRef.id}/${key}.jpg`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Subiendo imagen ${key}: ${progress}% hecho`);
            },
            (error) => {
              console.error("Error al subir la imagen:", error);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve({ name: key, url: downloadURL });
            }
          );
        });
      });

      if (firma) {
        const firmaBlob = await (await fetch(firma)).blob();
        const firmaRef = ref(
          storage,
          `inspecciones/${inspectionId || newInspectionRef.id}/Firma.png`
        );
        const uploadTask = uploadBytesResumable(firmaRef, firmaBlob);

        const firmaURL = await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Subiendo firma: ${progress}% hecho`);
            },
            (error) => {
              console.error("Error al subir la firma:", error);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });

        uploadPromises.push(Promise.resolve({ name: "Firma", url: firmaURL }));
      }

      const uploadedImages = await Promise.all(uploadPromises);
      const uploadedFotos = uploadedImages.reduce((acc, img) => {
        if (img) {
          acc[img.name] = img.url;
        }
        return acc;
      }, {});

      const updatedFotos = {
        ...(await (await getDoc(newInspectionRef)).data().fotos), // Obtener las fotos existentes
        ...uploadedFotos, // Combina con las nuevas fotos subidas
      };

      await updateDoc(newInspectionRef, {
        "Inspection ID": inspectionId,
        fotos: updatedFotos,
        Nombre: selectedOficial, // Asegúrate de actualizar el nombre del oficial
        Timing: { ...timing, End: Timestamp.now() }, // Usamos Timestamp para la fecha final
      });

      setMessage("Inspección creada con éxito.");
      setOpenSnackbar(true);
      resetForm();
    } catch (error) {
      console.error("Error al crear la inspección:", error);
      setMessage("Error al crear la inspección. Intenta nuevamente.");
      setOpenSnackbar(true);
    } finally {
      setUploading(false);
    }
  };

  const resetForm = () => {
    setComentario("");
    setSelectedUnidad("");
    setSelectedOficial("");
    setFotos({});
    setFirma(null);
    setGeneralState({
      Bocina: "Incorrecto",
      Radio: "Desprogramado",
      Torreta: "Incorrecto",
      DVR: "Incorrecto",
    });
    setFusibles([]);
    setActiveStep(0);
    setTiming({ Start: "", End: "" });
    setInspectionId("");
  };

  const steps = [
    "Seleccionar Unidad",
    "Subir Fotografías",
    "Estado y Comentarios",
    "Oficial y Firma",
  ];

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper
        sx={{
          p: 4,
          borderRadius: 4,
          boxShadow: "0 8px 24px rgba(0, 255, 255, 0.2)",
          backgroundColor: "black",
          color: "#00ffff",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            color: "#00ffff",
            borderBottom: "3px solid #00ffff",
            paddingBottom: "12px",
            fontWeight: "bold",
          }}
        >
          {uid ? "Editar Inspección" : "Nueva Inspección"}
        </Typography>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            mb: 4,
            "& .MuiStepLabel-root .Mui-completed": {
              color: "#00ffff",
            },
            "& .MuiStepLabel-root .Mui-active": {
              color: "#00ffff",
            },
            "& .MuiStepLabel-label": {
              color: "#00ffff",
            },
            "& .MuiStepLabel-root .MuiStepIcon-text": {
              fill: "#000000",
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <Step1Unidad
            unidades={unidades}
            selectedUnidad={selectedUnidad}
            setSelectedUnidad={setSelectedUnidad}
            handleNext={handleNext}
            setUnidades={setUnidades}
            setInspectionId={setInspectionId}
          />
        )}

        {activeStep === 1 && (
          <Step2Fotos
            existingFotos={fotos}
            handleImageChange={handleImageChange}
            handleNext={handleNext}
            handleBack={handleBack}
            inspectionId={inspectionId}
          />
        )}

        {activeStep === 2 && (
          <Step3EstadoComentarios
            comentario={comentario}
            setComentario={setComentario}
            status={status}
            setStatus={setStatus}
            handleNext={handleNext}
            handleBack={handleBack}
            generalState={generalState}
            setGeneralState={setGeneralState}
            fusibles={fusibles}
            setFusibles={setFusibles}
            inspectionId={inspectionId}
          />
        )}

        {activeStep === 3 && (
          <Step4OficialFirma
            oficiales={oficiales}
            selectedOficial={selectedOficial}
            setSelectedOficial={setSelectedOficial}
            firma={firma}
            handleFirmaPad={handleFirmaPad}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            uploading={uploading}
            inspectionId={inspectionId}
          />
        )}
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity={message.includes("éxito") ? "success" : "error"}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default NuevaInspeccion;
