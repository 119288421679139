import React, { useState, useEffect } from "react";
import { db } from "../../firebase"; // Asegúrate de importar tu configuración de Firebase
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  LinearProgress,
} from "@mui/material";

// Función para convertir la fecha y hora a un Timestamp
const convertToTimestamp = (dateStr) => {
  let date;
  if (dateStr.includes(",")) {
    // Caso de fecha con hora en formato 'd/m/yyyy, hh:mm:ss AM/PM'
    const [datePart, timePart] = dateStr.split(", ");
    const [day, month, year] = datePart.split("/").map(Number);

    // Convertimos el formato de hora AM/PM a formato 24 horas
    let [time, modifier] = timePart.split(" ");
    let [hours, minutes, seconds] = time.split(":").map(Number);

    if (modifier === "p.m." && hours < 12) {
      hours += 12; // Convertir PM a formato 24h
    }
    if (modifier === "a.m." && hours === 12) {
      hours = 0; // Convertir medianoche de 12 AM a 00h
    }

    date = new Date(year, month - 1, day, hours, minutes, seconds);
  } else {
    // Caso de fecha sin hora en formato 'd/m/yyyy'
    const [day, month, year] = dateStr.split("/").map(Number);
    // Asignamos hora por defecto: 12:00 PM
    date = new Date(year, month - 1, day, 12, 0, 0);
  }
  return Timestamp.fromDate(date); // Convertimos a Timestamp de Firestore
};

const ConvertDatesIndividually = () => {
  const [loading, setLoading] = useState(true);
  const [inspections, setInspections] = useState([]);
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0); // Estado para controlar el progreso

  // Obtener todas las inspecciones de Firestore
  useEffect(() => {
    const fetchInspections = async () => {
      try {
        const snapshot = await getDocs(collection(db, "inspecciones"));
        const inspectionsData = snapshot.docs.map((docSnapshot) => ({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        }));
        setInspections(inspectionsData);
        setLoading(false);
      } catch (error) {
        console.error("Error al recuperar inspecciones:", error);
        setMessage("Error al recuperar inspecciones.");
        setLoading(false);
      }
    };

    fetchInspections();
  }, []);

  // Convertir fecha individualmente
  const convertIndividualDate = async (inspection) => {
    try {
      const { id, Date: inspectionDate } = inspection;
      if (typeof inspectionDate === "string") {
        const timestamp = convertToTimestamp(inspectionDate);

        // Actualizar el documento en Firestore con el Timestamp
        const docRef = doc(db, "inspecciones", id);
        await updateDoc(docRef, { Date: timestamp });

        setMessage(`Fecha de la inspección ${id} convertida correctamente.`);
      } else {
        setMessage(`La fecha de la inspección ${id} ya es un Timestamp.`);
      }
    } catch (error) {
      console.error("Error al convertir la fecha:", error);
      setMessage(
        `Error al convertir la fecha de la inspección ${inspection.id}.`
      );
    }
  };

  // Convertir todas las fechas que aún no han sido convertidas a Timestamp
  const convertAllDates = async () => {
    setLoading(true);
    const total = inspections.filter(
      (inspection) => typeof inspection.Date === "string"
    ).length;
    let convertedCount = 0;

    try {
      for (const inspection of inspections) {
        if (typeof inspection.Date === "string") {
          await convertIndividualDate(inspection);
          convertedCount += 1;
          setProgress(Math.round((convertedCount / total) * 100)); // Actualizar el progreso
        }
      }
      setMessage("Todas las fechas han sido convertidas correctamente.");
    } catch (error) {
      console.error("Error al convertir las fechas:", error);
      setMessage("Ocurrió un error al convertir todas las fechas.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Convertir Fechas Individualmente
      </Typography>

      {message && (
        <Typography variant="body1" color="success" gutterBottom>
          {message}
        </Typography>
      )}

      {/* Botón para convertir todas las fechas no convertidas */}
      <Button
        variant="contained"
        color="primary"
        onClick={convertAllDates}
        sx={{ mb: 3 }}
        disabled={
          loading ||
          inspections.filter((i) => typeof i.Date === "string").length === 0
        }
      >
        Convertir Todas las Fechas No Convertidas
      </Button>

      {/* Barra de progreso */}
      {progress > 0 && (
        <Box sx={{ width: "100%", mb: 3 }}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body2" color="textSecondary">
            {progress}%
          </Typography>
        </Box>
      )}

      <List>
        {inspections.map((inspection) => (
          <Box key={inspection.id}>
            <ListItem>
              <ListItemText
                primary={`Folio: ${inspection.id}`}
                secondary={
                  <>
                    <Typography variant="body2">
                      <strong>Fecha actual:</strong>{" "}
                      {inspection.Date.toString()}
                    </Typography>
                    {typeof inspection.Date === "string" && (
                      <Typography variant="body2">
                        <strong>Fecha convertida:</strong>{" "}
                        {convertToTimestamp(inspection.Date)
                          .toDate()
                          .toString()}
                      </Typography>
                    )}
                  </>
                }
              />
              {typeof inspection.Date === "string" ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => convertIndividualDate(inspection)}
                >
                  Convertir a Timestamp
                </Button>
              ) : (
                <Button variant="contained" disabled>
                  Ya es Timestamp
                </Button>
              )}
            </ListItem>
            <Divider />
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default ConvertDatesIndividually;
